.container {


  margin: 0 auto;
  color: #fff;
  box-shadow: 4px 5px 14px -5px #333;
  .column-left {
    background-color: #191A1C;
  }
  // .column-right {
  //   display: flex;
  //   flex-direction: column;
  //   flex-basis: 20%;
  //   .icons {
  //     background: #2DB3E9;
  //     font-weight: 400;
  //   }
  // }
}
.weather-app {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  .column-left {
    flex-basis: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      margin: 0;
      font-size: 80px;
      font-weight: 300;
      & + span {
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 8px;
      }
    }
  }
  .icon-weather {
  
  
    justify-content: center;
    float: left;
    align-items: center;
    .wi {
      color: #e9ed17;
      font-size: 40px;
    }
  }
  .icons {
    height: 60%;
    display: flex;
    align-items: center;
    text-align: center;
    height: 70px;
    & > div {
      flex-basis: 50%;
    }
    .wi-raindrop,
    .wi-small-craft-advisory {
      font-size: 22px;
      margin-right: 5px;
      position: relative;
      top: 2px;
    }
  }
}