@media screen and (max-width:768px)
{
    .baslik{
        font-size: 14px;
     }
   
}
@media only screen and (min-width: 992px) {
    .baslik{ font-size:2vh;}
    .video{
        width:100%;
        height: 52vh;
     }
}
