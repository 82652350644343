.side-inner {
   
    height: 100vh;
     background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    .side-inner-header {
   
        height: 5vh;
         background-size: cover;
        background-position: center center;
        background-repeat: no-repeat; }